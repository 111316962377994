export class WindowSessionStorage {
    getItem(key) {
        if (!isSessionStorageAvailable()) {
            return null;
        }
        try {
            return window.sessionStorage.getItem(key);
        }
        catch (_e) {
            return null;
        }
    }
    setItem(key, value) {
        if (!isSessionStorageAvailable()) {
            return null;
        }
        try {
            return window.sessionStorage.setItem(key, value);
        }
        catch (_e) {
            return null;
        }
    }
    removeItem(key) {
        if (!isSessionStorageAvailable()) {
            return null;
        }
        try {
            return window.sessionStorage.removeItem(key);
        }
        catch (_e) {
            return null;
        }
    }
}
function isSessionStorageAvailable() {
    return typeof window.sessionStorage === "object";
}
