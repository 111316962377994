import { matchURLAggregationRule, } from "@adora/backend-api";
import { unreachable } from "@adora/lib/src/utils/unreachable";
import { debugLog } from "./debug";
/**
 * Checks if how a URL should react to recording.
 *
 * Returns the URL if allowed (possibly redacted).
 * Returns blocked if the URL is blocked.
 */
export function urlAccepted({ urlStr, blockedPaths, allowedPaths, pathConfig, }) {
    const url = new URL(urlStr);
    const pathname = url.pathname;
    for (const rule of pathConfig.rules) {
        const match = matchURLAggregationRule(pathname, rule);
        if (match) {
            switch (rule.action) {
                case "allow":
                    return url;
                case "block":
                    return undefined;
                case "replace":
                    url.pathname = match;
                    return url;
                default:
                    unreachable(rule.action);
            }
        }
    }
    // Legacy; will be removed later and combined with pathConfig.
    if (allowedPaths.length > 0) {
        for (const allowedPath of allowedPaths) {
            if (matchURLAggregationRule(pathname, { path: allowedPath })) {
                return url;
            }
        }
    }
    for (const blockedPath of blockedPaths) {
        if (matchURLAggregationRule(pathname, { path: blockedPath })) {
            return undefined;
        }
    }
    return pathConfig.defaultAction === "allow" ? url : undefined;
}
// When going from blocked to unblocked, give it a gap in case
// old mutations are still within.
// This alleviates the case where rrweb does the following:
// * we are at a blocked URL with DOM data we should not transmit
// * the URL changes from blocked to unblocked
// * we notice on the mutation observer and start the recording
// * rrweb takes a full snapshot
// * the DOM is mutated out into data we can consume
export function checkAcceptedURLAfterTimeout({ organization, onSuccess, }) {
    setTimeout(() => {
        // Only re-enable recording if the URL we are now at after the timeout
        // is still accepted.
        // Calling start() is idempotent.
        if (urlAccepted({
            urlStr: window.location.href,
            blockedPaths: organization.blockedPaths,
            allowedPaths: organization.allowedPaths,
            pathConfig: organization.pathConfig,
        })) {
            debugLog(`non-blocked URL after start, proceeding...`);
            onSuccess();
            return;
        }
        debugLog(`still at blocked URL`);
    }, 5000);
}
