export function debugLog(message, ...args) {
    if (process.env.NODE_ENV === "development" ||
        (typeof window === "object" &&
            window
                .enableAdoraDebugLogs) ||
        (typeof window === "object" &&
            window.localStorage.getItem("enableAdoraDebugLogs"))) {
        //biome-ignore lint/suspicious/noConsole: Log is used for debugging
        console.log(message, ...args);
    }
}
