export var SessionType;
(function (SessionType) {
    SessionType["Extension"] = "adoraSessionExtension";
    SessionType["Script"] = "adoraSessionScript";
})(SessionType || (SessionType = {}));
export var EventStorageKey;
(function (EventStorageKey) {
    EventStorageKey["Script"] = "adoraEventsScript";
    EventStorageKey["Extension"] = "adoraEventsExtension";
})(EventStorageKey || (EventStorageKey = {}));
export class AdoraEventStorageImpl {
    storage;
    eventStorageKey;
    constructor(sessionType, storage) {
        this.storage = storage;
        this.eventStorageKey =
            sessionType === SessionType.Script
                ? EventStorageKey.Script
                : EventStorageKey.Extension;
    }
    getEvents() {
        const serializedEvents = this.storage.getItem(this.eventStorageKey);
        if (!serializedEvents) {
            return null;
        }
        return JSON.parse(serializedEvents);
    }
    setEvents(events) {
        this.storage.setItem(this.eventStorageKey, JSON.stringify(events));
    }
    removeEvents() {
        this.storage.removeItem(this.eventStorageKey);
    }
}
function lastRateLimitedTimestampKey(sessionType) {
    return `${sessionType}-rate-limit-timestamp`;
}
export class AdoraSessionStorageImpl {
    sessionType;
    storage;
    constructor(sessionType, storage) {
        this.sessionType = sessionType;
        this.storage = storage;
    }
    get lastRateLimitedTimestamp() {
        const timestamp = this.storage.getItem(lastRateLimitedTimestampKey(this.sessionType));
        if (isNumber(timestamp)) {
            return timestamp;
        }
        return undefined;
    }
    clearLastRateLimitedTimestamp() {
        this.storage.removeItem(lastRateLimitedTimestampKey(this.sessionType));
    }
    setLastRateLimitedTimestamp(timestamp) {
        this.storage.setItem(lastRateLimitedTimestampKey(this.sessionType), timestamp.toString());
    }
    getSession() {
        const serializedSession = this.storage.getItem(this.sessionType);
        if (!serializedSession) {
            return null;
        }
        return JSON.parse(serializedSession);
    }
    setSession(session) {
        this.storage.setItem(this.sessionType, JSON.stringify(session));
    }
    removeSession() {
        this.storage.removeItem(this.sessionType);
    }
}
function isNumber(toCheck) {
    return !isNaN(Number(toCheck));
}
